import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { spacing } from '@material-ui/system';
import styles from './Auction.module.css';
import img from './asta_fantacalcio.png'

export default (props) => {
	const player = props.player

	const [ stats, setStats ] = useState(0)

	let statsList = [ { name: '2020', key: 'stats2020'}, { name: '2019', key: 'stats2019'} ]

	let playerCard
	if (player == null) {
		playerCard = (
			<Card className={styles.emptyPlayer}>
				<CardMedia
		        className={styles.emptyImg}
		        image={img}
		      />
			</Card>
		)
	} else {

		let currentStat = player[statsList[stats].key]

		let statsBox = null

		statsBox = (
			<Box className={styles.statsContainer}>
          	  { statsList.map((s,i) => {
          	  		if (Object.keys(player[s.key]).length > 0)
          	  			return (
          	  				<Chip color={ i == stats ? 'primary' : '' } key={s.key} label={s.name} onClick={ () => setStats(i) } />
      	  				)
          	  	})}
      	  	</Box>
		)

		if (Object.keys(currentStat).length  == 0)
			setStats(0)

		playerCard = (
			<Card className={styles.player}>
		        <CardContent className={styles.playerStatsContainer}>
		          <Typography component="h5" variant="h5">
		            {player.name}
		          </Typography>
		          <Typography variant="subtitle1" color="textSecondary">
		            {player.team} <Chip size="small" label={player.role} />
		          </Typography>
		        	{ statsBox }
		          <ul className={styles.playerData}>
		        	<li><b>Giocate</b>: {currentStat.played}</li>
		        	<li><b>{player.role == 'P' ? "Gol subiti" : "Gol"}</b>: { currentStat.goals }</li>
		        	<li><b>Assist</b>: { currentStat.assists }</li>
		        	<li><b>Gialli</b>: { currentStat.yellows }</li>
		        	<li><b>Rossi</b>: { currentStat.reds }</li>
		        	<li><b>{player.role == 'P' ? "Rigori parati" : "Rigori"}</b>: { currentStat.penalties }</li>
		        	<li><b>Media</b>: { currentStat.fantasyAvg }</li>
		        	<li><b>Valore</b>: {player.quote}</li>
		        </ul>
		        </CardContent>
		      <CardMedia
		        className={styles.playerImg}
		        image={player.stats2020.imageUrl}
		        title="Live from space album cover"
		      />
	    	</Card>
    	)
	}

	return (
		<Box height={props.height || null}>
			{playerCard}
		</Box>
	)
}
