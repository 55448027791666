import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
      screen: null,
  },
  reducers: {
    navigateTo: (state, action) => {
      state.screen = action.payload
    },
  },
});

export const { navigateTo } = mainSlice.actions;

export default mainSlice.reducer;
