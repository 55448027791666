import { createSlice } from '@reduxjs/toolkit';

const TIMER_VALUE = 180

export const auctionSlice = createSlice({
  name: 'auction',
  initialState: {
      // isRunning: false,
      isPaused: false,
      shoudlChoosePlayer: false,
      current: {
        player: null,
        bid: 0,
        winner: '',
        iAmWinning: false,
        iCanPartecipate: true,
        maxBet: 0,
        timer: TIMER_VALUE
      },
      availablePlayers: [],
      previousWinner: null,
      role: null,
      showTeam: window.innerWidth >= 900 ? true : false
  },
  reducers: {
    bid: (state, action) => {
      const{bid, user, player, auth, role, time} = action.payload
      // state.isRunning = true
      state.current.player = player
      state.current.bid = bid
      state.current.winner = user.name
      state.current.iAmWinning = user.id == auth.id
      state.current.time = time - 2
      state.previousWinner = null
      state.role = role
      console.log(auth, state.role)
      state.current.iCanPartecipate = auth.players[state.role].length < auth.rolesConfig[state.role]
      state.isPaused = false
    },
    choosePlayer: (state, action) => {
      const {user, players, winnerPreviousBid, role, auth} = action.payload
      state.shoudlChoosePlayer = user == auth.id
      // state.isRunning = false
      state.availablePlayers = players
      state.previousWinner = winnerPreviousBid || null
      state.role = role
      state.isPaused = false
    },
    toggleTeam: (state, action) => {
      if (window.innerWidth < 900)
        state.showTeam = !state.showTeam
    },
    pause: (state, action) => {
      const { winnerPreviousBid } = action.payload
      state.previousWinner = winnerPreviousBid || null
      state.isPaused = true
    }
  },
});

export const { bid, choosePlayer, toggleTeam, pause } = auctionSlice.actions;

export default auctionSlice.reducer;
