import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { register, start } from '../../app/action'

import styles from './Login.module.css';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogoContainer from '../../components/LogoContainer'

const mapDispatch = { register, start }
const mapState = state => state

const Login = ({ user, register, start }) => {

  const [name, setName] = useState('');
  const box = user.name == ''
  ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={2}>
            <TextField onChange={ e => setName(e.target.value)} label="Inserisci il tuo nome" placeholder="Nome" fullWidth mt={2} mb={2}/>
      </Box>
      <Button variant="contained" color="primary" onClick = { e => register({ name: name, id: user.id}) } >Inserisci</Button>
    </Box>
  )
  : (
      <Box display="flex" flexDirection="column" alignItems="center">
        <span>Ciao { user.name }</span>
        <span>L'asta sta per cominciare</span>
        <Box mt={2}>
          <CircularProgress />
        </Box>
      </Box>
  )

  return (
    <LogoContainer fullWidth={true}>
      {box}
    </LogoContainer>
  );
}

export default connect(mapState, mapDispatch)(Login)
