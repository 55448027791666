import React from 'react';
import img from './asta_fantacalcio.png'
import Box from '@material-ui/core/Box';
import styles from './Components.module.css'

const LogoContainer = (props) => {

  let style = styles.logoContainer

  if (props.fullWidth === true) {
  	style = styles.fullWidthLogoContainer
  }

  return (
    <Box className={style}>
      <Box mb={2}>
      	<img width="140px" src = { img }/>
      </Box>
      { props.children }
    </Box>
  )
}

export default LogoContainer
