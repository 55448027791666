import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  toggleTeam
} from './auctionSlice';

import styles from './Auction.module.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { spacing } from '@material-ui/system';
import PeopleIcon from '@material-ui/icons/People';

const mapDispatch = { toggleTeam }
const mapState = state => state

const UserMenu = ({ user, auction, toggleTeam}) => {

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1}}>
            <b>{ user.credits } crediti</b> (MAX { user.maxBet })
          </Typography>
          <PeopleIcon onClick={ (e) => toggleTeam() }/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(mapState, mapDispatch)(UserMenu)
