import React,{useState} from 'react';

import styles from './Auction.module.css';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


const WinnerBanner = ({ previousWinner, user }) => {
  let [show, display] = useState(true)

  const handleClose = () => {
    display(false)
  }

  if (previousWinner != null && show) {
      const text = previousWinner.user.id == user.id ? "Hai" : previousWinner.user.name + ' ha'
      return (
        <Snackbar open={show} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="info">
            {text} comprato { previousWinner.player.name } per { previousWinner.value }
          </Alert>
        </Snackbar>
      )
  }

  return (<div></div>)
}

export default WinnerBanner
