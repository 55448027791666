import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
    players: [],
    name: '',
    id: null,
    credits: 0,
    rolesConfig: {},
    maxBet: 0
  },
  reducers: {
    updateUser: (state, action) => {
      const {players, credits} = action.payload[state.id]
      state.credits = credits
      state.players = players
      state.maxBet = getMaxBet(state.credits, state.rolesConfig, state.players)
    },
    registerUser: (state, action) => {
      const {id, user, credits, players, rolesConfig} = action.payload
      state.name = user
      state.id = id
      state.credits = credits
      state.players = players
      state.rolesConfig = rolesConfig
      state.maxBet = getMaxBet(state.credits, state.rolesConfig, state.players)
    },
    connectUser: (state, action) => {
      const { id } = action.payload
      state.id = id
    },
    reconnectUser: (state, action) => {
      const {users, id} = action.payload
      state.id = id
      const {players, credits, rolesConfig} = users[id]
      state.credits = credits
      state.players = players
      state.rolesConfig = rolesConfig
      state.maxBet = getMaxBet(state.credits, state.rolesConfig, state.players)
    }
  },
});

const getMaxBet = (credits, rolesConfig, players) =>
    credits - ['P','D','C','A'].reduce((a,r) => {
        return a + (rolesConfig[r] - players[r].length)
    },0) + 1

export const { connectUser, reconnectUser, updateUser, registerUser } = loginSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default loginSlice.reducer;
