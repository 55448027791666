import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { init } from '../../app/action'
import Cookie from 'js-cookie'
import img from './asta_fantacalcio.png'

const style = {
	width: "100vw",
	height: "100vh",
	position: "fixed",
	objectFit: "contain"
}

const mapDispatch = { init }
const mapState = state => state

const Initial = ({ init }) => {

  useEffect(() => {
  	console.log(Cookie.get('deviceId') || null)
    init(Cookie.get('deviceId') || null)
  }, []);

  return (
    <img src={img} style={style} />
  )
}

export default connect(mapState, mapDispatch)(Initial)
