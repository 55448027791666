import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.value/props.startingValue*100}/>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{props.value}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: "10px"
  },
});

export default function Timer(props) {
  	const classes = useStyles()

  	// React.useEffect(() => {
  	// 	setTimeout(() => props.onChange(props.reset ? props.startingValue : props.value - 1), 1000)
  	// }, [ props.value ])
  	React.useEffect(() => {
  		if (props.value == props.startingValue)
  			props.onTimer()
  	},[])
  	// 	setTimeout(() => props.onChange(props.reset ? props.startingValue : props.value - 1), 1000)
  	// }, [ props.value ])

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={props.value} startingValue={props.startingValue} />
    </div>
  );
}
