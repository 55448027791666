import React from 'react';
import logo from './logo.svg';
import Main from './features/main/Main';
import './App.css';

function App() {
  return (
      <Main />
  );
}

export default App;
