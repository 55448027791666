import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  bid
} from '../../app/action';

import styles from './Auction.module.css';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { spacing } from '@material-ui/system';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Player from './Player'
import Timer from './Timer'
import UserMenu from './UserMenu'
import WinnerBanner from './WinnerBanner'
import LogoContainer from '../../components/LogoContainer'
import MyTeam from './MyTeam'

const mapDispatch = { bid }
const mapState = state => state

const Auction = ({ user, auction, bid }) => {

  const [betValue, setBetAmount] = useState(0);
  const [timer, setTimer] = useState(auction.current.time)
  const [timerEvent, setTimerEvent] = useState(null)
  const [isDisabled, disable] = useState(false)

  //const players = [...user.players.P, ...user.players.D, ...user.players.C, ...user.players.A]
  const myTeam = auction.showTeam ? <MyTeam roles={user.rolesConfig} players={user.players} /> : null

  // let isDisabled = null
  let timerComponent = null
  let previousWinner = null

  const setTimerHelper =  () => {
    console.log('setTimerInterval')
    setTimerEvent(setInterval(() => {
      console.log('timer event', timer)
      setTimer((prevProgress) => {
        return prevProgress == 0 ? prevProgress : prevProgress - 1
      })
    }, 1000))
  }

  const pass = () => {
    disable(true)
    bid(null)
  }

  useEffect(() => {
    return () => {
      console.log('cleanup generic', timerEvent)
      clearInterval(timerEvent)
    }
  },[ timerEvent ])

  useEffect(() => {
    if (auction.current.bid > 1) {
      console.log('set timer number')
      setTimer(auction.current.time)
    }
  }, [auction.current.bid])

  if (!isDisabled && (!auction.current.iCanPartecipate || user.maxBet <= auction.current.bid)) {
    disable(true)
    bid(null)
  }

  const tempDisabled = timer == 0 || auction.current.iAmWinning

  // isDisabled = timer == 0 || !auction.current.iCanPartecipate || user.maxBet <= auction.current.bid ? true : null
  timerComponent = timer > 0 && !auction.isPaused
    ? <Timer mt={2} startingValue={auction.current.time} value={timer} onTimer = {setTimerHelper} />
    : null

  const winText = auction.current.iAmWinning ? 'Hai puntato' : auction.current.winner + ' ha puntato '

  const box = !auction.isPaused ? (
      <Box className={styles.auctionContainer}>
        <Player player={auction.current.player} />
        <Box className={styles.bid}>
          <span>{ winText}</span><span className={styles.bidValue}>{auction.current.bid}</span>
        </Box>
        <div className={styles.bidPanel}>
          <div className={styles.fixedBids}>
            <Button variant="contained" color="primary" disabled={isDisabled || auction.current.bid + 1 > user.maxBet || tempDisabled} onClick = { () => bid(auction.current.bid + 1) }>
              {auction.current.bid + 1}
            </Button>
            <Button variant="contained" color="primary" disabled={ isDisabled || auction.current.bid + 5 > user.maxBet  || tempDisabled} onClick = { () => bid(auction.current.bid + 5) }>
              {auction.current.bid + 5}
            </Button>
            <Button variant="contained" color="primary" disabled={isDisabled || auction.current.bid + 10 > user.maxBet  || tempDisabled} onClick = { () => bid(auction.current.bid + 10) }>
              {auction.current.bid + 10}
            </Button>
          </div>
          <div className={styles.passBid}>
            <Button variant="contained" color="primary" disabled={isDisabled  || tempDisabled} onClick = { () => pass() }>Passa</Button>
          </div>
          <div className={styles.customBid}>
            <TextField max = { user.maxBet } disabled={isDisabled} variant="outlined" type="number" InputLabelProps={{shrink: true}} onChange={ e => setBetAmount(parseInt(e.target.value))} label="Valore" placeholder="Valore" mt={2} mb={2}/>
            <Button variant="contained" color="primary" disabled={ isDisabled || betValue > user.maxBet  || tempDisabled} onClick = { () => bid(betValue) } >Punta</Button>
          </div>
        </div>
        {timerComponent}
      </Box>
  ) : <Box className={styles.logoBoxContainer}>
        <WinnerBanner previousWinner={ auction.previousWinner } user={user} />
        <LogoContainer>
          <span>L'asta e' in pausa</span>
          <Box mt={2}>
            <CircularProgress />
          </Box>
        </LogoContainer>

      </Box>

  return (
    <Box>
      <UserMenu />
      <Box className={styles.rowContainer}>
        {myTeam}
        {box}
      </Box>
    </Box>
  );
}

export default connect(mapState, mapDispatch)(Auction)
