import React from 'react';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import styles from './Auction.module.css';

const MyTeam = (props) => {

  const players = props.players

  return (
	  <div className={ styles.myTeamModal }>
	  	<TableContainer component={Paper}>
	        <Table aria-label="simple table">
	          <TableHead>
	            <TableRow>
	              <TableCell align="center" component="th">Ruolo</TableCell>
	              <TableCell align="center" component="th">Giocatore</TableCell>
	              <TableCell align="center" component="th">Squadra</TableCell>
	              <TableCell align="center" component="th">Prezzo</TableCell>
	            </TableRow>
	          </TableHead>
	          <TableBody>
	            { Object.entries(props.roles).map(([role, count]) => (
	            	Array.from(Array(count).keys()).map(num => (
		              <TableRow key={role + '-' + num}>
		                <TableCell align="center"><Chip size="small" label={ role } /></TableCell>
		                <TableCell align="center">{ players[role][num] !== undefined ? players[role][num].name : null }</TableCell>
		                <TableCell align="center">{ players[role][num] !== undefined ? players[role][num].team : null }</TableCell>
		                <TableCell align="center">{ players[role][num] !== undefined ? players[role][num].price : null }</TableCell>
		              </TableRow>
	            	)))
            	)}
	          </TableBody>
	        </Table>
	      </TableContainer>
	  </div>
  )
}

export default MyTeam
