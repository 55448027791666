import React, { useState } from 'react';
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box';
import Login from '../login/Login'
import Auction from '../auction/Auction'
import Choose from '../auction/Choose'
import Initial from './Initial'

const mapState = state => state.screen

const Main = ({ screen, user }) => {

  const Screen = ( (type) => {
		switch (type) {
			case 'Login':
				return Login
			case 'Auction':
				return Auction
			case 'Choose':
				return Choose
			default:
				return Initial
		}
	})(screen)

  return (
    <Screen />
  )
}

export default connect(mapState, null)(Main)
