import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  choosePlayer
} from '../../app/action';

import styles from './Auction.module.css';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { spacing } from '@material-ui/system';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Player from './Player'
import Timer from './Timer'
import UserMenu from './UserMenu'
import WinnerBanner from './WinnerBanner'
import LogoContainer from '../../components/LogoContainer'
import MyTeam from './MyTeam'

const mapDispatch = { choosePlayer}
const mapState = state => state

const Choose = ({ user, auction, choosePlayer}) => {
  const [selectedPlayer, selectPlayer] = useState(null);
  //const player = selectedPlayer == null ? <div style={{height: "310px"}}></div> : <Player height="310px" player={selectedPlayer.player} />
  let previousWinner = null

  //const players = [...user.players.P, ...user.players.D, ...user.players.C, ...user.players.A]
  const myTeam = auction.showTeam ? <MyTeam roles={user.rolesConfig} players={user.players} /> : null
  //const myTeam = auction.showTeam ? <MyTeam players={players} /> : null

  // if (auction.previousWinner != null) {
  //   const text = auction.previousWinner.user.id == user.id ?
  //   previousWinner = (
  //     <Box className={styles.bid} my={1}>
  //       <span>{ auction.previousWinner.user.name } ha comprato { auction.previousWinner.player.name } per { auction.previousWinner.value }</span>
  //     </Box>
  //   )
  // }

  const label = ( (role) => {
    switch (role) {
      case 'P':
        return 'Seleziona il portiere'
      case 'D':
        return 'Seleziona il difensore'
      case 'C':
        return 'Seleziona il centrocampista'
      case 'A':
        return "Seleziona l'attaccante"
      default:
        return 'Seleziona il portiere'
    }
  })(auction.role)

  const box = auction.shoudlChoosePlayer
      ? (
          <Box className={styles.auctionContainer}>
            <Player height="310px" player={selectedPlayer == null ? null : selectedPlayer.player} />
            <Box mt={7} mb={1}>
              <Autocomplete
                width="80%"
                disableClearable
                options={ Object.values(auction.availablePlayers) }
                getOptionLabel={(option) => option.name + " - " + option.team + " ("+ option.quote +")"}
                renderInput={(params) => <TextField {...params} label={ label } />}
                onChange={(e, player) => selectPlayer({ player }) }
              />
            </Box>
            <Button variant="contained" color="primary" onClick = { e => {
              if (selectedPlayer !== null)
                choosePlayer(selectedPlayer)
            } }> Seleziona</Button>
          </Box>
      ) : (
        <Box className={styles.logoBoxContainer}>
          <LogoContainer>
            <span>Attendi un altro allenatore ... </span>
            <Box mt={2}>
              <CircularProgress />
            </Box>
          </LogoContainer>
        </Box>
    )

  return (
    <Box>
      <UserMenu />
      <WinnerBanner previousWinner={ auction.previousWinner } user={user} />
      <Box className={ styles.rowContainer }>
        {myTeam}
        {box}
      </Box>
    </Box>
  );
}

export default connect(mapState, mapDispatch)(Choose)
