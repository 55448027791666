import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import mainReducer from '../features/main/mainSlice';
import auctionReducer from '../features/auction/auctionSlice';
import { socketMiddleware } from './socket';
import { authMiddleware } from './auth';


const rootReducer = (state, action) => {
	if (action.type == 'resetApp')
		state = undefined

	return combineReducers({
		user: loginReducer,
    	auction: auctionReducer,
    	screen: mainReducer
	})(state, action)
}

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware).concat(socketMiddleware),
});
