import { createAction } from '@reduxjs/toolkit'

//socket actions
export const init = createAction('socket/SERVER_CONNECT')
export const register = createAction('socket/SERVER_REGISTRATION')
export const start = createAction('socket/SERVER_START')
export const choosePlayer = createAction('socket/SERVER_CHOOSE')
export const bid = createAction('socket/SERVER_BID')

//state actions
export const reset = createAction('resetApp')
