import io from 'socket.io-client';
import { connectUser, reconnectUser, updateUser, registerUser } from '../features/login/loginSlice'
import { bid, choosePlayer, pause } from '../features/auction/auctionSlice'
import { navigateTo } from '../features/main/mainSlice'
import { reset } from './action'

const handleSocketEvent = (store, message) => {
	let action = ( (type) => {
		switch (type) {
			case 'CLIENT_CHOOSE':
				return choosePlayer
			case 'CLIENT_REGISTRATION':
				return registerUser
			case 'CLIENT_UPDATE_USERS':
				return updateUser
			case 'CLIENT_RESET':
			 	return reset
			case 'CLIENT_BID':
				return bid
			case 'CLIENT_WAIT':
				return pause
			case 'CLIENT_CONNECT':
				return connectUser
			case 'CLIENT_RECONNECT':
				return reconnectUser
			default:
				return null
		}
	})(message.type)

	if (action !== null) {
		const payloadMessage = {
			...message.payload,
			auth: store.getState().user
		}

		store.dispatch(action(payloadMessage))
	}

	if (message.type == 'CLIENT_BID') {
		store.dispatch(navigateTo('Auction'))
	} else if (message.type == 'CLIENT_CHOOSE') {
		store.dispatch(navigateTo('Choose'))
	} else if (message.type == 'CLIENT_RESET') {
		store.dispatch(navigateTo('Login'))
	} else if (message.type == 'CLIENT_WAIT') {
		store.dispatch(navigateTo('Auction'))
	} else if (message.type == 'CLIENT_REGISTRATION') {
		store.dispatch(navigateTo('Login'))
	} else if (message.type == 'CLIENT_CONNECT') {
		setTimeout(() => store.dispatch(navigateTo('Login')), 50)
	}
}

export const socketMiddleware = (store) => {
	let socket = null

	return function(next){
	    return function(action) {
	    	if (action.type.startsWith('socket')) {
	    		const type = action.type.split('socket/')[1]
	    		if (type == 'SERVER_CONNECT') {
	    			socket = io('https://thrilling-sand-hail.glitch.me');
					socket.on('message', message =>
						console.log(message)
					);

					socket.on('event', payload => {
						console.log('received', 'event', payload)
						handleSocketEvent(store, payload)
					})

					console.log(action.payload)

					socket.emit('message', {
	    				type: "SERVER_CONNECT_CLI",
	    				payload: null,
	    				user: action.payload
	    			})

	    		} else {
	    			console.log('emitted', type, action.payload)
	    			socket.emit('message', {
	    				type: type,
	    				payload: action.payload,
	    				user: store.getState().user != null ? store.getState().user.id : null
	    			})
	    		}
	    	} else {
	    		console.log('other action', action)
	    		return next(action);
	    	}
	    }
	}
}

